import {
    User,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
} from "firebase/auth";

import {
    FC,
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";

import { firebaseAuth } from "../Firebase/config";
import { firestoreDB } from "../Firebase/config";
import {doc, setDoc, getDoc, collection} from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import {Travel} from "../Objects/Travel";
import { arrayUnion } from "firebase/firestore";

export type UserContextType = {

    user?: User;

    isLoading: boolean;

    signup: (
        email: string,
        password: string,
        firstname: string,
        lastname: string,
        travels: Travel[],
        navigate: (path:string) => void
    ) => Promise<{ user?: User; error?: string }>;

    signin: (
        email: string,
        password: string
    ) => Promise<{ user?: User; error?: string }>;

    logout: () => Promise<void>;

    updateUser:(
        uid: string,
        data: {firstname: string, lastname: string, travels: Travel[]}
    ) => Promise<void>;

    getUser: (uid: string) => Promise<{firstname:string; lastname:string; travels: Travel[]}>;

    saveTravel:(userId:string, newTravel: Travel) => Promise<string>;
};

const UserContext = createContext<UserContextType>({
    signup: async () => ({}),
    signin: async () => ({}),
    logout: async () => {},
    isLoading: true,
    updateUser: async () => {},
    getUser: async () => ({firstname: "", lastname: "", travels: []}),
    saveTravel: async () => "",
});

export const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        onAuthStateChanged(firebaseAuth, (user) => {
            setIsLoading(false);
            if (user) {
                setUser(user);
            } else {
                setUser(undefined);
            }
        });
    }, []);

    const signup = async (
        email: string,
        password: string,
        firstname: string,
        lastname: string,
        travels: Travel[],
        navigate: (path: string) => void
    ): Promise<{ user?: User; error?: string }> => {
        try {
            const userCredential = await createUserWithEmailAndPassword(
                firebaseAuth,
                email,
                password
            );

            setUser(userCredential.user);

            //Extra information added to the user
            if (userCredential.user) {
                await setDoc(doc(firestoreDB, "users", userCredential.user.uid), {
                    firstname: firstname,
                    lastname: lastname,
                    travels: travels
                });

                //Navigate to the profile details page
                navigate(`/${userCredential.user.uid}/ProfileDetailsPage`);
            }

            return {user: userCredential.user};
        } catch (error) {
            console.error(error);

            return {error: (error as { message: string; code: string }).message};
        }
    };

    const signin = async (
        email: string,
        password: string
    ): Promise<{ user?: User; error?: string }> => {
        try {
            const userCredential = await signInWithEmailAndPassword(
                firebaseAuth,
                email,
                password
            );

            setUser(userCredential.user);

            return {user: userCredential.user};
        } catch (error) {
            console.error(error);

            return {error: (error as { message: string; code: string }).message};
        }
    };

    const logout = async () => {
        await firebaseAuth.signOut();
        setUser(undefined);
    };

    const updateUser = async (uid: string, data: {firstname: string, lastname: string, travels: Travel[]}) => {
        const userDocRef = doc(firestoreDB, "users", uid);
        await setDoc(userDocRef, data,{merge: true});
    };

        const getUser = async (userId: string) => {
            const userDocRef = doc(firestoreDB, `users/${userId}`);
            const userDoc = await getDoc(userDocRef);
            return userDoc.exists() ? (userDoc.data() as { firstname: string; lastname: string; travels: Travel[]; }) : {
                firstname: "",
                lastname: "",
                travels: []
            };
        };

        const saveTravel = async (userId: string, newTravel: Travel) => {
            // Correctly referencing the travels collection within a user's document
            const travelsCollectionRef = collection(firestoreDB, "users", userId, "travels");
            const travelDocRef = doc(travelsCollectionRef, newTravel.travelId);
            await setDoc(travelDocRef, newTravel);

            return newTravel.travelId;
        };

        //The value of the context
        const value: UserContextType = {user, isLoading, signup, signin, logout, updateUser, getUser, saveTravel};

        return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
    };

    const findUserById = async (userId: string) => {
        const userDoc = await getDoc(doc(firestoreDB, "users", userId));
        if (userDoc.exists()) {
            return userDoc.data() as User;
        } else {
            console.error('User with ID ${userId} not found');
            return null;
        }
    }
    export const useUserContext = (): UserContextType => {
        return useContext(UserContext);
    };

