import React from 'react';
import {Box, Button} from '@mui/material';
import {FC, useState, useEffect} from "react";
import { useUserContext } from "../Providers/userProvider";
import {useNavigate, useParams} from 'react-router-dom';
import {Travel} from "./Travel";
import logoCheckIt from "../../logoCheckIt.jpg";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface HeaderProps {
    bottomRef: React.RefObject<HTMLDivElement>;
}


const Header:FC<HeaderProps> = ({ bottomRef }) => {
    const [travel, setTravel] = useState<Travel | null>(null);
    const { logout } = useUserContext();
    const {user, getUser} = useUserContext();
    const {travelId} = useParams();
    const navigate = useNavigate();

    const handleLogout = async () => {
        await logout();
        navigate("/");
    };

    useEffect(() => {
        if (user) {
            getUser(user.uid).then((data) => {
                const travel = data.travels.find((t: Travel) => t.travelId === travelId) || null;
                setTravel(travel);
            });
        }
    }, [user, getUser, travelId]);

    const onGoToOverviewTravels = () => {
        navigate(`/${user?.uid}/OverviewTravelsPage`)
    }

    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    };



    return (
        <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%" paddingBottom={2} paddingTop={2} gap={2}>
            <Box sx={{ width: { xs: '100%', sm: '50%', md: '30%', lg: '20%', xl: '15%' } }}>
                <img src={logoCheckIt} alt="CheckIt logo" style={{width: '100%', height: 'auto'}}/>
            </Box>

            <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
                <Button variant="contained" onClick={onGoToOverviewTravels}>Travels</Button>
                <Button variant="contained" onClick={handleLogout}>Logout</Button>
            </Box>
            <Box display="flex" justifyContent="flex-end" sx={{width:'100%'}}>
                <Button variant="contained" color="primary" onClick={scrollToBottom}>
                    <ArrowDownwardIcon />
                </Button>
            </Box>
        </Box>

);
};

export default Header;