import { FC } from "react";
import {BrowserRouter as Router, RouterProvider} from 'react-router-dom';
import { UserProvider } from "./Functionalities/Providers/userProvider";
import {router} from "./Functionalities/Router/router";
import {Container} from "@mui/material";

export const App: FC = () => {
    return (
        <Router>
            <UserProvider>
                <Container maxWidth="xl">
                    {router}
                </Container>
            </UserProvider>
        </Router>
    );
};