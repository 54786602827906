import {Routes, Route} from "react-router-dom";
import {PrivateRoute} from "./privateRoute";
import {Homepage} from "../../Pages/Homepage";
import {ProfileDetailsPage} from "../../Pages/ProfileDetailsPage";
import {OverviewTravelsPage} from "../../Pages/OverviewTravelsPage";
import {CreateNewTravelPage} from "../../Pages/CreateNewTravelPage";
import {AddRemoveItemsPage} from "../../Pages/AddRemoveItemsPage";
import {PackingPage} from "../../Pages/PackingPage";
import {TravelDetailsPage} from "../../Pages/TravelDetailsPage";

export const router = (
    <Routes>

        <Route path="/" element={<Homepage />} />
        <Route path="/" element={<PrivateRoute />}>
            <Route path=":uid/ProfileDetailsPage" element={<ProfileDetailsPage />} />
            <Route path=":uid/OverviewTravelsPage" element={<OverviewTravelsPage />} />
            <Route path=":uid/CreateTravelPage" element={<CreateNewTravelPage />} />
            <Route path=":uid/AddRemoveItemsPage/:travelId" element={<AddRemoveItemsPage />} />
            <Route path=":uid/PackingPage/:travelId" element={<PackingPage />} />
            <Route path=":uid/TravelDetailsPage/:travelId" element={<TravelDetailsPage />} />
        </Route>

    </Routes>
);
