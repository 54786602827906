import {Box, CardActionArea, CardContent, TextField, Typography, Checkbox, FormControlLabel, Divider} from "@mui/material";
import React, {FC, useEffect, useState} from "react";
import { Card } from '@mui/material';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {Travel} from "../Functionalities/Objects/Travel";
import { useUserContext } from "../Functionalities/Providers/userProvider";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import {doc, deleteDoc, setDoc} from "firebase/firestore";
import {firestoreDB} from "../Functionalities/Firebase/config";
import Header from "../Functionalities/Objects/Header";
import AddIcon from "@mui/icons-material/Add";

export const OverviewTravelsPage: FC = () => {
    const [travels, setTravels] = useState<Travel[]>([]);
    const [showFutureTravels, setShowFutureTravels] = useState(true);
    const [showPastTravels, setShowPastTravels] = useState(false);
    const {user, getUser} = useUserContext();

    const navigate = useNavigate();

    const handleFutureTravelsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowFutureTravels(event.target.checked);
    }

    const handlePastTravelsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowPastTravels(event.target.checked);
    }

    const filteredTravels = travels.filter(travel => {
        const isPastTravel = new Date(travel.departureDate) < new Date();
        return (showPastTravels && isPastTravel) || (showFutureTravels && !isPastTravel);
    });


    useEffect(()=>{
        if(user){
            console.log('User:', user);
            console.log('User ID:', user.uid);
            getUser(user.uid).then((data)=> {
                console.log('Travels:', data.travels);
                const sortedTravels = data.travels.sort((a, b) => {
                    const aDate = new Date(a.departureDate).getTime();
                    const bDate = new Date(b.departureDate).getTime();
                    const now = Date.now();
                    if (aDate > now && bDate > now) {
                        // Both travels are in the future, sort in ascending order
                        return aDate - bDate;
                    } else if (aDate < now && bDate < now) {
                        // Both travels are in the past, sort in descending order
                        return bDate - aDate;
                    } else {
                        // One travel is in the future and the other is in the past, put the future travel first
                        return aDate > now ? -1 : 1;
                    }
                });
                setTravels(sortedTravels);
            });
        }
    }, [user, getUser]);

    const onCreateNewTravel = () => {navigate("/${user.uid}/CreateTravelPage")};

    const deleteTravel = async (travelId: string) => {
        if(user) {
            // Remove the travel from the travels collection in FirestoreDB
            const travelDocRef = doc(firestoreDB, "travels", travelId);
            await deleteDoc(travelDocRef);

            // Remove the travel from the user's travels subcollection in FirestoreDB
            const userTravelDocRef = doc(firestoreDB, `users/${user.uid}/travels`, travelId);
            await deleteDoc(userTravelDocRef);

            // Remove the travel from travels array
            const userData = await getUser(user.uid);
            const updatedTravels = userData.travels.filter((travel) => travel.travelId !== travelId);
            await setDoc(doc(firestoreDB, "users", user.uid), {travels: updatedTravels}, {merge: true});
            setTravels(updatedTravels);
        }}

           const goToTravelDetails = (travelId: string) => {
        if(user){
            navigate(`/${user.uid}/TravelDetailsPage/${travelId}`);
        }
    }

    const bottomRef = React.useRef<HTMLDivElement>(null);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Header bottomRef={bottomRef}/>
            <Typography variant="h4" mt={5}>My travels </Typography>
            <Box sx={{width:'80%'}}>
                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    <FormControlLabel
                        control={
                            <Checkbox checked={showPastTravels} onChange={handlePastTravelsChange} name="pastTravelsCheckbox" color="primary"/>}
                        label="Past Travels"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={showFutureTravels} onChange={handleFutureTravelsChange} name="futureTravelsCheckbox" color="primary"/>}
                        label="Future Travels"
                    />
                </Box>

                <Box display="flex" flexDirection="row" justifyContent="left" alignItems="center" gap={2} ml={1} mt={2}>
                    <AddIcon onClick={onCreateNewTravel} style={{cursor: 'pointer', fontSize:'30px'}}/>
                </Box>

                <Box>
                    <Card variant="outlined" style={{border: '1px solid transparent'}}>
                        <CardContent>
                            {filteredTravels.map((travel, index) => {
                                const isPastTravel = new Date(travel.departureDate) < new Date();
                                return(
                                    <Box  key={index} mt={3}>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <CardActionArea onClick={() => goToTravelDetails(travel.travelId)}>
                                                <Typography variant="body1" className={isPastTravel ? 'past-travel' : ''}>
                                                    Destination: {travel.destination}
                                                </Typography>
                                                <Typography variant="body1" className={isPastTravel ? 'past-travel' : ''}>
                                                    Departure Date: {travel.departureDate}
                                                </Typography>
                                            </CardActionArea>
                                            <DeleteSweepIcon onClick={() => deleteTravel(travel.travelId)} />
                                        </Box>
                                        <Divider/>
                                    </Box>
                                );
                            })}
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            <Box ref={bottomRef}/>
        </Box>



    );
}