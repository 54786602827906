// Your web app's Firebase configuration
import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import{getAuth} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBk28PJ6Ti-M9oXWVezQ0xOUj8antIu2Nc",
    authDomain: "checkit-sdb1.firebaseapp.com",
    projectId: "checkit-sdb1",
    storageBucket: "checkit-sdb1.appspot.com",
    messagingSenderId: "478878977287",
    appId: "1:478878977287:web:e381c1cfc597617b8209a2"
};


export const firebaseApp = initializeApp(firebaseConfig);
export const firestoreDB = getFirestore(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);