import React, {FC, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { useUserContext } from "../Functionalities/Providers/userProvider";
import { Travel } from "../Functionalities/Objects/Travel";
import {Box, Button, Card, CardContent, Divider, Typography} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {Item} from "../Functionalities/Objects/Item";
import {collection, doc, getDocs, setDoc} from "firebase/firestore";
import {firestoreDB} from "../Functionalities/Firebase/config";
import Header from "../Functionalities/Objects/Header";
import BottomNavigation from "../Functionalities/Objects/BottomNavigation";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";


export const TravelDetailsPage: FC = () => {
    const [travel, setTravel] = useState<Travel | null>(null);
    const [checkedItems, setCheckedItems] = useState<Item[]>([]);
    const[uncheckedItems, setUncheckedItems] = useState<Item[]>([]);
    const {user, getUser} = useUserContext();
    const {travelId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            getUser(user.uid).then((data) => {
                const travel = data.travels.find((t: Travel) => t.travelId === travelId)||null;
                setTravel(travel);
            });

            const fetchUncheckedItems = async () => {
                const uncheckedItemsCollection = collection(firestoreDB, `users/${user.uid}/travels/${travelId}/uncheckedItems`);
                const uncheckedItemsSnapshot = await getDocs(uncheckedItemsCollection);
                const uncheckedItemsList = uncheckedItemsSnapshot.docs.map(doc => ({...doc.data(), itemId: doc.id} as Item));
                setUncheckedItems(uncheckedItemsList);
            }
            fetchUncheckedItems();
        }
    }, [user, getUser, travelId]);

    const onGoBackToPacking = () => {
        if (user) {
            navigate(`/${user.uid}/PackingPage/${travelId}`)
        }
    }

    const onGoToOverviewTravels = () => {
        navigate(`/${user?.uid}/OverviewTravelsPage`)
    }

    const calculateDaysRemaining = (departureDate: string) => {
        const currentDate = new Date();
        const departureDateObj = new Date(departureDate);
        const differenceInTime = departureDateObj.getTime() - currentDate.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays;
    }

    const bottomRef = React.useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Header bottomRef={bottomRef}/>
            <Typography variant="h4" mt={5}>Your travel details</Typography>
            <Box sx={{width:'80%'}}>
                {travel && (
                    <Box mt={3}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6">Destination: {travel.destination}</Typography>
                                <Typography variant="h6">Departure Date: {new Date(travel.departureDate).toLocaleDateString()}</Typography>
                                <Typography variant="h6">Days Remaining before departure: {calculateDaysRemaining(travel.departureDate)}</Typography>
                            </CardContent>
                        </Card>
                    </Box>
                )}

                <Typography variant="h5" mt={3}>Still to pack</Typography>
                {uncheckedItems.length === 0 ? (
                <Typography variant="h6">Nothing left to pack! Enjoy your holidays!</Typography>
                ) : (
                uncheckedItems.map((item) => (
                    <Box key={item.itemId}>
                        <Card variant="outlined" style={{border: '1px solid transparent'}}>
                            <CardContent>
                                <Box display="flex" flexDirection="row" justifyContent="left" alignItems="center">
                                <Typography display="flex" flexDirection="row" justifyContent="left" alignItems="center"variant="body1">{item.itemName}</Typography>
                                </Box>
                                <Box>
                                <Typography display="flex" flexDirection="row" justifyContent="right" alignItems="center"variant="body1">{item.itemQuantity}</Typography>
                                </Box>
                            </CardContent>
                            <Divider/>
                        </Card>
                    </Box>
                ))
                )}
                <Box display="flex" flexDirection="row" justifyContent="right" alignItems="center" gap={2} mt={3}>
                    <Button variant="contained" color="primary" onClick={onGoBackToPacking}>Continue packing</Button>
                    <Button variant="contained" color="primary" onClick={onGoToOverviewTravels}>Go to your travels</Button>
                </Box>
            </Box>
            <Box ref={bottomRef}/>
            <BottomNavigation/>
        </Box>
    );
};