import React, {useEffect, useState} from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined';
import Looks3OutlinedIcon from '@mui/icons-material/Looks3Outlined';
import Looks4OutlinedIcon from '@mui/icons-material/Looks4Outlined';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useUserContext} from "../Providers/userProvider";
import {Travel} from "./Travel";

const MyBottomNavigation = () => {
    const [value, setValue] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const {user} = useUserContext();
    const {travelId} = useParams();

    const GoToPackingPage= () => {
        if(user) {
        navigate(`/${user?.uid}/PackingPage/${travelId}`);
        }
    }

    const GoToAddRemoveItemsPage= () => {
        if (user) {
            navigate(`/${user?.uid}/AddRemoveItemsPage/${travelId}`);
        }
    }

    const GoToTravelDetailsPage= () => {
        if (user) {
            navigate(`/${user?.uid}/TravelDetailsPage/${travelId}`);
        }
    }

    useEffect(() => {
        const match = location.pathname.match(/\/[^/]+\/([^/]+)/);
        if (match) {
            switch (match[1]) {
                case "CreateNewTravelPage":
                    setValue(0);
                    break;
                case "AddRemoveItemsPage":
                    setValue(1);
                    break;
                case "PackingPage":
                    setValue(2);
                    break;
                case "TravelDetailsPage":
                    setValue(3);
                    break;
                default:
                    setValue(0);
            }
        }
    }, [location]);


    return (
        <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            showLabels
            sx={{ bottom: 5, width: '100%', mt: 5 }}

        >
            <BottomNavigationAction label="Create new travel" icon={<LooksOneOutlinedIcon />}  disabled/>
            <BottomNavigationAction label="Add items to pack" onClick={GoToAddRemoveItemsPage} icon={<LooksTwoOutlinedIcon />}/>
            <BottomNavigationAction label="Start packing" onClick={GoToPackingPage} icon={<Looks3OutlinedIcon />}/>
            <BottomNavigationAction label="Your travel details" onClick={GoToTravelDetailsPage} icon={<Looks4OutlinedIcon />}/>
        </BottomNavigation>
    );
};

export default MyBottomNavigation;