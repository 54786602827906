import { Box, CircularProgress } from "@mui/material";
import { FC, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useUserContext } from "../Providers/userProvider";

export const PrivateRoute: FC = () => {
    const { user, isLoading } = useUserContext();

    const navigate = useNavigate();

    useEffect(() => {
        if (!user && !isLoading) {
            navigate("/login", { replace: true });
        }
    }, [user, navigate, isLoading]);

    if (isLoading) {
        return (
            <Box>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box height="100vh" display="flex" flexDirection="column">
            <Box flexGrow={1} overflow="auto">
                <Outlet />
            </Box>
        </Box>
    );
};
